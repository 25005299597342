import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataIterator } from 'vuetify/lib/components/VDataIterator';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDataIterator,{attrs:{"items":_vm.items,"hide-default-footer":""},scopedSlots:_vm._u([{key:"item",fn:function(props){return [_c(VRow,[_c(VCol,{attrs:{"cols":12}},[_c(VCard,[_c(VList,{attrs:{"dense":""}},[_c(VListItem,[_c(VListItemContent,[_vm._v("Name:")]),_c(VListItemContent,[_vm._v(_vm._s(props.item.name))])],1),_c(VListItem,[_c(VListItemContent,[_vm._v("Age:")]),_c(VListItemContent,[_vm._v(_vm._s(props.item.age))])],1),_c(VListItem,[_c(VListItemContent,[_vm._v("Sex:")]),_c(VListItemContent,[_vm._v(_vm._s(props.item.gender))])],1)],1)],1)],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }