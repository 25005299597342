import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTextarea } from 'vuetify/lib/components/VTextarea';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VDialog,{attrs:{"max-width":"540px"},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }return _vm.closeDialog.apply(null, arguments)}},model:{value:(_vm.dialogLocal),callback:function ($$v) {_vm.dialogLocal=$$v},expression:"dialogLocal"}},[_c(VCard,[_c(VCardTitle,[_c('span',{staticClass:"headline",staticStyle:{"color":"#FFFD00","background-color":"#363636","width":"100%","display":"block"},attrs:{"align":"center"}},[_vm._v("Send Message")])]),_c(VCardText,[(_vm.waiting)?_c(VProgressLinear,{attrs:{"indeterminate":true}}):_vm._e(),_c(VTextField,{attrs:{"rules":_vm.titleRules,"counter":"35","label":"Message Title"},model:{value:(_vm.title),callback:function ($$v) {_vm.title=$$v},expression:"title"}}),_c(VContainer,{attrs:{"pt-3":"","pb-0":"","px-0":""}},[_c(VTextarea,{attrs:{"rules":_vm.bodyRules,"outlined":"","counter":"140","label":("Send message to: " + _vm.orgName)},model:{value:(_vm.body),callback:function ($$v) {_vm.body=$$v},expression:"body"}})],1)],1),_c(VCardActions,{staticClass:"mr-2"},[_c(VSpacer),_c(VBtn,{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.closeDialog}},[_vm._v("Cancel")]),_c(VBtn,{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.send}},[_vm._v("Send")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }