import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VRow,{attrs:{"justify":"center"}},[_c(VDialog,{attrs:{"persistent":"","max-width":"400"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c(VCard,[_c(VCardTitle,{staticClass:"headline",staticStyle:{"color":"#FFFD00","background-color":"#363636"}},[_vm._v("Add a Card")]),_c('div',{ref:"card",staticClass:"ma-4"}),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.cancel}},[_vm._v("Cancel")]),_c(VBtn,{attrs:{"color":"blue darken-1","loading":_vm.waiting,"disabled":_vm.stripeError || _vm.waiting || !_vm.complete,"text":""},on:{"click":_vm.addCard}},[_vm._v("Add Card")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }