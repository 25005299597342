import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataIterator } from 'vuetify/lib/components/VDataIterator';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDataIterator,{attrs:{"items":_vm.items,"hide-default-footer":""},scopedSlots:_vm._u([{key:"item",fn:function(props){return [_c(VRow,[_c(VCol,{attrs:{"cols":12}},[_c(VCard,[_c(VList,{attrs:{"dense":""}},[_c(VListItem,[_c(VListItemContent,[_vm._v("Pulse Level:")]),_c(VListItemContent,[_vm._v(_vm._s(props.item.torchLevel))])],1),_c(VListItem,[_c(VListItemContent,[_vm._v("Flash Status:")]),_c(VListItemContent,[_vm._v(_vm._s(props.item.flashOn))])],1),_c(VListItem,[_c(VListItemContent,[_vm._v("Video Duration:")]),_c(VListItemContent,[_vm._v(_vm._s(props.item.videoDuration))])],1),_c(VListItem,[_c(VListItemContent,[_vm._v("Pulse Duration:")]),_c(VListItemContent,[_vm._v(_vm._s(props.item.torchDuration))])],1),_c(VListItem,[_c(VListItemContent,[_vm._v("Ambient Adapt Brightness Level:")]),_c(VListItemContent,[_vm._v(_vm._s(props.item.ambientLevel))])],1),_c(VListItem,[_c(VListItemContent,[_vm._v("Illumination Delay:")]),_c(VListItemContent,[_vm._v(_vm._s(props.item.illuminationDelay))])],1),_c(VListItem,[_c(VListItemContent,[_vm._v("Dropped Frames Count:")]),_c(VListItemContent,[_vm._v(_vm._s(props.item.droppedFramesCount))])],1),_c(VListItem,[_c(VListItemContent,[_vm._v("Produced with Reflex version:")]),_c(VListItemContent,[_vm._v(_vm._s(props.item.processedBy))])],1),(props.item.testProtocolTag)?_c(VListItem,[_c(VListItemContent,[_vm._v("Test Protocol Tag:")]),_c(VListItemContent,[_vm._v(_vm._s(props.item.testProtocolTag))])],1):_vm._e()],1)],1)],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }