import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VAppBarNavIcon } from 'vuetify/lib/components/VAppBar';
import { VImg } from 'vuetify/lib/components/VImg';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbarItems } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VAppBar,{attrs:{"fixed":"","app":""}},[_c(VAppBarNavIcon,{on:{"click":_vm.menuSelected}}),_c(VToolbarTitle,[_vm._v(" "+_vm._s(_vm.$route.meta.title)+" ")]),_c(VSpacer),_c(VToolbarItems,[_c(VImg,{staticClass:"my-2",attrs:{"src":require('../assets/images/logo b text.svg'),"alt":"Logo","width":"192","height":"42"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }