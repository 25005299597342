import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextarea } from 'vuetify/lib/components/VTextarea';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VContainer,[_c(VToolbar,{staticClass:"mb-2 indigo white--text",attrs:{"dense":"","flat":""}},[_c(VRow,{attrs:{"align":"center"}},[_c(VCol,[_c(VToolbarTitle,[_vm._v("Notes")])],1),_c(VCol,{attrs:{"md":"2"}},[_c(VBtn,{attrs:{"color":"primary","disabled":!_vm.needToSave},on:{"click":function($event){return _vm.saveNote()}}},[_vm._v("Save Note")])],1)],1)],1),(_vm.waitingLocal)?_c(VProgressLinear,{attrs:{"indeterminate":true}}):_vm._e(),_c(VTextarea,{attrs:{"placeholder":"Enter your test notes here.","outlined":"","single-line":"","auto-grow":""},on:{"input":function($event){return _vm.noteChanged()}},model:{value:(_vm.items[0].notes),callback:function ($$v) {_vm.$set(_vm.items[0], "notes", $$v)},expression:"items[0].notes"}}),_c(VCard,[_c(VCardTitle,[_c('span',{staticClass:"headline",staticStyle:{"color":"#FFFD00","background-color":"#363636","width":"100%","display":"block"},attrs:{"align":"center"}},[_vm._v("Notes History")])]),_c(VCardText,[_c('span',{domProps:{"innerHTML":_vm._s(_vm.items[0].notesHistoryFormatted)}})])],1),_c(VToolbar,{staticClass:"mt-2 indigo",attrs:{"dense":"","flat":""}},[_c(VRow,{attrs:{"justify":"end"}},[_c(VCol,{attrs:{"md":"2"}},[_c(VBtn,{attrs:{"color":"primary","disabled":!_vm.needToSave},on:{"click":function($event){return _vm.saveNote()}}},[_vm._v("Save Note")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }