import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VRow,{attrs:{"justify":"center"}},[_c(VDialog,{attrs:{"persistent":"","max-width":_vm.params.maxWidth ? _vm.params.maxWidth : 310},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c(VCard,[_c(VCardTitle,{staticClass:"headline",staticStyle:{"color":"#FFFD00","background-color":"#363636"}},[_vm._v(_vm._s(_vm.params.title))]),_c(VCardText,{staticClass:"mt-2",staticStyle:{"color":"#000000"}},[_vm._v(_vm._s(_vm.params.text))]),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":_vm.params.positiveColor ? _vm.params.positiveColor : 'blue darken-1',"text":""},on:{"click":_vm.negativeResponse}},[_vm._v(_vm._s(_vm.params.negativeResponse))]),_c(VBtn,{attrs:{"color":_vm.params.negativeColor ? _vm.params.negativeColor : 'blue darken-1',"text":""},on:{"click":_vm.positiveResponse}},[_vm._v(_vm._s(_vm.params.positiveResponse))])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }