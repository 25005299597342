import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataIterator } from 'vuetify/lib/components/VDataIterator';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDataIterator,{attrs:{"items":_vm.items,"hide-default-footer":""},scopedSlots:_vm._u([{key:"item",fn:function(props){return [_c(VRow,[_c(VCol,{attrs:{"cols":12}},[_c(VCard,[_c(VList,{attrs:{"dense":""}},[_c(VListItem,[_c(VListItemContent,[_vm._v("Concussion Date:")]),_c(VListItemContent,[_vm._v(_vm._s(props.item.concussionDate))])],1),_c(VListItem,[_c(VListItemContent,[_vm._v("Is Asymptomatic:")]),_c(VListItemContent,[_vm._v(_vm._s(props.item.asymptomatic))])],1),_c(VListItem,[_c(VListItemContent,[_vm._v("Glasgow Coma Scale:")]),_c(VListItemContent,[_vm._v(_vm._s(props.item.glasgowComaScale))])],1)],1)],1)],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }